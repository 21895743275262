.Auth-body-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  background-color: #282c34;
}

.Auth-body-wrapper * {
  font-size: 15px;
}

.Auth-logo {
  align-self: center;
  height: 6rem;
  position: relative;
  margin-bottom: 5vh;
}

.Auth-title {
  text-align: center;
  font-size: xx-large;
  width: 70%;
  align-self: center;
}

.Auth-container-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: monospace;
  justify-content: center;
  /* min-height: 80vh; */
  max-width: fit-content;
  /* padding-bottom: 10rem; */
  text-align: center;
  /* margin-top: 10vh; */
}

.Auth-container {
  background-color: #98a9b1;
  border-color: #000;
  border-width: 1rem;
  border-radius: 10px;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

/* If desktop display */
@media only screen and (min-width: 1024px) {
  .Auth-container {
    min-width: 450px;
    padding: 2rem;
  }
  #Auth-oauth-google {
    background: url(./../public/g.png) no-repeat left center;
    background-size: 1.5rem;
    background-position-x: 1.5rem;
  }
  #Auth-oauth-google:hover {
    background: url(./../public/g.png) no-repeat left center, lightslategray;
    background-size: 1.5rem;
    background-position-x: 1.5rem;
  }
}

.Auth-input-email,
.Auth-input-email-entered {
  margin-top: 5vh;
  margin-bottom: 3vh;
}

.Auth-input-email-entered {
  cursor: pointer;
}

.Auth-input-email-entered:hover span {
  background-color: rgb(172, 47, 47);
}

.Auth-input-email-entered span:after {
  content: "@";
  /* display: none; */
}

.Auth-input-email span:after {
  content: "@";
  /* display: none; */
}

.Auth-input-email-entered:hover span:after {
  content: "X";
  display: inline; /* show changed text */
}

.Auth-input-pass {
  margin-top: -2vh;
  margin-bottom: 3vh;
}

.Auth-input-button {
  margin-bottom: 3vh;
}

.Auth-input-label {
  background: lightslategrey;
  color: white;
}

.Auth-alternative-link {
  cursor: pointer;
  display: inline-flex;
  text-decoration: underline;
}

.Auth-divider {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: center;
  padding: 1rem 1rem 1rem;
  margin-bottom: 1rem;
}

.Auth-divider:before,
.Auth-divider:after {
  content: "";
  border-bottom: 1px solid #c2c8d0;
  flex: 1 0 auto;
  height: 0.5em;
  margin: 0;
}

.Auth-divider-or {
  text-align: center;
  flex: 0.2 0 auto;
  margin: 0;
  height: 12px;
}

.Auth-oauth-button {
  cursor: pointer;
}

.Auth-oauth-button:hover {
  background-color: lightslategray;
}
