@keyframes spin-animation {
  0% {
    transform: translate(2px, 2px);
  }
  33.33% {
    transform: translate(102px, 2px);
  }
  66.66% {
    transform: translate(42px, 102px);
  }
  100% {
    transform: translate(2px, 2px);
  }
}

.loading-magnifier-icon > div {
  transform: scale(0.8);
  transform-origin: 100px 100px;
}

.loading-magnifier-icon > div > div {
  animation: spin-animation 1s linear infinite;
  position: absolute;
}

.loading-magnifier-icon > div > div div:nth-child(1) {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border: 12px solid #5c6866;
  background: #718c87;
}

.loading-magnifier-icon > div > div div:nth-child(2) {
  width: 17px;
  height: 51px;
  transform: rotate(-45deg);
  background: #5c6866;
  border-radius: 0 0 8px 8px;
  position: absolute;
  top: 68px;
  left: 85px;
}
.loading-spinner {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.loading-magnifier-icon {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.loading-magnifier-icon div {
  box-sizing: content-box;
}
/* [ldio] generated by https://loading.io */

.loading-ring,
.loading-ring div {
  box-sizing: border-box;
}
.loading-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  color: rgb(70, 90, 100);
}
.loading-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.loading-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
