.Topic-card {
  margin-bottom: 1rem;
  margin-top: 2rem;
  max-width: 20rem;
}

.Topic-list-container {
  /* overflow-y: scroll; */
  scroll-behavior: smooth;
  min-width: max-content;
}

/* Scrollbar handle on hover */
.Topic-list-container:active::-webkit-scrollbar-thumb,
.Topic-list-container:focus::-webkit-scrollbar-thumb,
.Topic-list-container:hover::-webkit-scrollbar-thumb {
  visibility: visible !important;
  background: #888 !important;
}

/* Burger menu */
/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  height: 30px;
  left: 36px;
  top: 36px;
}

.bm-burger-button:hover .bm-icon {
  color: lightseagreen;
  cursor: pointer;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: lightslategray;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: auto !important;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  width: min-content;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding-bottom: 10vh;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-icon {
  font-size: x-large;
  text-wrap-mode: nowrap;
  color: lightslategray;
  animation: bounce-in 0.5s ease 1;
}

.bm-icon-bounce {
  animation: bounce 2s ease 3;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
