.Breakdown-body {
  background-color: #282c34;
  margin: auto;
  color: #fff;
  display: grid !important;
  place-items: center;
  flex-direction: row;
  font-family: Anton;
  min-height: 100vh;
  margin-left: auto;
  overflow-wrap: anywhere;
  justify-content: center;
  position: relative;
  overflow-y: scroll;
}

/* If desktop display flex columns  */
@media only screen and (min-width: 1024px) {
  .Breakdown-body {
    display: flex !important;
    max-height: 100vh !important;
  }
  .Breakdown-left-column {
    order: 0 !important;
    width: 20%;
  }
  .Breakdown-middle-column {
    order: 1 !important;
    min-height: 100vh;
    padding: 0 10% 0 10%;
    margin-right: 5vw;
  }
  .Breakdown-right-column {
    order: 2 !important;
  }
}

.Breakdown-left-column {
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
  max-height: 100vh;
  min-height: 100vh;
  order: 1;
}

/* Scrollbar handle on hover */
.Breakdown-left-column:active::-webkit-scrollbar-thumb,
.Breakdown-left-column:focus::-webkit-scrollbar-thumb,
.Breakdown-left-column:hover::-webkit-scrollbar-thumb {
  visibility: visible !important;
  background: #888 !important;
}

.Breakdown-middle-column {
  order: 0;
  display: flex;
  flex-direction: column;
  align-self: baseline;
  overflow-y: auto;
  max-height: 100vh;
}

/* Scrollbar handle on hover */
.Breakdown-middle-column:active::-webkit-scrollbar-thumb,
.Breakdown-middle-column:focus::-webkit-scrollbar-thumb,
.Breakdown-middle-column:hover::-webkit-scrollbar-thumb {
  visibility: visible !important;
  background: #888 !important;
}

.Breakdown-right-column {
  order: 2;
  display: flex;
  flex-direction: column;
}

.Breakdown-topic-container {
  max-width: 45rem;
  margin-right: 2rem;
  margin-left: 2rem;
  padding-top: 2rem;
  flex-direction: column;
}

.Breakdown-topic-article {
  margin-bottom: 2rem;
  margin-top: 0;
  white-space: pre-line;
}

.Breakdown-content-card {
  margin-bottom: 2rem;
}

.Breakdown-content-container {
  display: block;
  margin: 2rem auto 3rem;
  max-height: 40vh;
  max-width: 100%;
  width: auto;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.Breakdown-content-source {
  display: inline-flex;
  margin-bottom: 1rem;
}

.Breakdown-content-tag-container {
  display: inline-flex;
  flex-wrap: wrap;
}

.Breakdown-audio-container {
  display: block;
  margin: 4rem auto 4rem;
  max-width: 100%;
}

.Breakdown-topic-title {
  margin-bottom: 2rem;
}

.Breakdown-toolbar {
  display: flex;
  margin-bottom: 2rem;
  justify-content: center;
  cursor: pointer;
}

.Breakdown-toolbar-icon {
  height: 25px;
}

.Breakdown-toolbar-icon:hover {
  box-shadow: inset 0 0 15px 15px transparent;
}

.Breakdown-share-icon {
  padding: 2%;
  margin-top: 1rem;
  text-align: center;
  width: 1rem;
}

.Breakdown-share-icon:hover {
  background-color: darkgray;
}

.Breakdown-topic-claims {
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.Breakdown-topic-claims-body {
  display: inline-flex;
  flex-direction: column;
}

.Breakdown-claim-evidence {
  display: inline-flex;
}

.Breakdown-claim-evidence-modal {
  text-align: center;
}

.Breakdown-claim-evidence-modal-url-input {
  font-family: monospace;
  font-size: medium;
  background-color: rgb(84, 100, 108);
  color: white;
}

.Breakdown-topic-claims-toolbar {
  display: inline-flex;
  justify-content: space-between;
  flex-flow: wrap;
}

.Breakdown-topic-claims-button {
  background-color: #282c34;
  border-color: black;
}

.Breakdown-topic-claims-tag {
  /* margin-top: 1rem; */
  margin-bottom: 1rem;
  margin-right: 0.5rem;
  display: inline-flex;
}

.loader {
  width: 120px;
  height: 20px;
  background: linear-gradient(90deg, #0001 33%, #0005 50%, #0001 66%)
    lightslategrey;
  background-size: 300% 100%;
  animation: l1 1s infinite linear;
  margin-left: 10px;
}
@keyframes l1 {
  0% {
    background-position: right;
  }
}

.Breakdown-loading {
  pointer-events: none;
  padding: 3vh;
  height: 28vh;
}

@media (prefers-reduced-motion: no-preference) {
  .Breakdown-loading {
    animation: Breakdown-loading-spin infinite 10s ease-in-out;
  }
}

@keyframes Breakdown-loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #282c34;
  box-shadow: inset 0 0 15px 15px transparent;
  border: solid 5px transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
  visibility: hidden;
  box-shadow: inset 0 0 15px 15px transparent;
  border: solid 5px transparent;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #888;
  visibility: visible;
} */
